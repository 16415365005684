import React from 'react'

import {
    HomeLinkWrapper,
    HomeLink
} from './HomeButtonElements'

const HomeButton = () => {
    return (
        <HomeLinkWrapper>
            <HomeLink to='/'>Return to 4U FDN</HomeLink>
        </HomeLinkWrapper>
    )
}

export default HomeButton
