import React from 'react'
import HomeButton from '../components/HomeButton';
import PartnerSection from '../components/PartnerSection'
import { partnerOne, partnerTwo } from '../components/PartnerSection/data'
import Footer from '../components/Footer'
import background from '../images/gradientBg.jpg';

const PartnershipsPage = () => {
    return (
        <div style={{background: `url(${background})`, backgroundSize: 'cover'}}>
            <HomeButton />
            <h1 style={{color: 'white', display: 'flex', justifyContent: 'center', marginTop: '50px', textDecorationLine: 'underline', fontSize: '45px'}}>Our Partners</h1>
            <PartnerSection {...partnerOne} />
            <PartnerSection {...partnerTwo} />
            <Footer />
        
        </div>
    )
}

export default PartnershipsPage
