import React, {useState} from 'react'
import { Button } from '../ButtonElements';
import { HeroContainer, HeroBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer id="home">
            <HeroBg>
            </HeroBg>
            <HeroContent>
                <HeroH1>For You, For Everyone</HeroH1>
                <HeroP>The term "charity" get's thrown around a lot to help sell NFT collections. 4U Foundation is setting the standard of what exactly a charity NFT should look like.</HeroP>
                <HeroP>We partner with various charitable organizations to help support their causes, and ours.</HeroP>
                <HeroBtnWrapper>
                    <Button to="about" 
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                    primary='true'
                    dark='true'
                    smooth={true} duration={500} spy={true} exact='true' offset={-80}>
                        Learn More {hover ? <ArrowForward /> : <ArrowRight style={{transform: 'scale(1.02)'}} />}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection;
