import React from 'react';
import {FaTwitter, FaInstagram, FaDiscord, FaTimes} from 'react-icons/fa';
import { useState, useEffect } from 'react';
import Carousel from "react-elastic-carousel";
import { ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Web3 from 'web3';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import  { providers } from 'web3modal';
import detectEthereumProvider from '@metamask/detect-provider'



import happy from "../images/happy.png"
import sad from "../images/sad.png"
import excited from "../images/excited.png"
import sample from "../images/sample.gif"
import background from "../images/cloudbackground.png"
import NKHBlack from "../images/NKHBlackLogo.png"
import FdnBlack from "../images/4UFdnBlackLogo.png"
import common from "../images/common-gif.gif"
import uncommon from "../images/uncommon-gif.gif"
import rare from "../images/rare-gif.gif"
import legendary from "../images/legendary-gif.gif"
import epic from "../images/epic-gif.gif"
import Cld1 from "../images/CloudButton1.png"
import Cld2 from "../images/CloudButton2.png"
import Cld3 from "../images/CloudButton3.png"
import Cld4 from "../images/CloudButton4.png"



// Import ABI + Config
import Shiny from '../abis/Shiny.json';
import CONFIG from '../config.json';

import {
    MintPageContainer,
    MintPageNavbar,
    NavbarLink,
    WalletLink,
	FoundationLink,
    MetamaskButton,
    MintPageMain,
    DescriptionPanel,
    AmountMinted,
    MintPanel,
	SponsorCardWrapper,
	SponsorCard,
	SponsorTitle,
	SponsorWrapper,
	SponsorLink,
	Sponsor,
    GridCol,
	GridCol2,
    ImgShowcase,
    ImgShowcaseImg,
    MintButton,
    TextWrapper,
    Heading,
	Title,
    Text,
    MintLabel,
    MintInput,
    FooterSection,
    FooterLinksWrapper,
    CurrentNetwork,
    FooterLinks,
    ContractInfo,
    ContractLink,
    ContractConnectedWrapper,
    NetworkLabel,
    RemainingNFTs,
	DeductionInstructionsH1,
	DeductionInstructionsP,
	DeductionInstructionsWrapper,
	WebsiteRights,
	RightsWrapper,
	ImgCycleImg,
	ImgCycleShowcase,
	PreviewPanel,
	List,
	SocialMediaWrap,
	SocialIcons,
	SocialIconLink,
	ProgressLabel,
	Connect,
	Rarity,
	RarityContainer,
	Body,
	TraitCard,
	ProgressWrapper,
	ProgressBarWrapper,
} from '../components/ShinyMint/MintingPageElements'

function ShinyPage() {
    const [Price, setPrice] = useState(0)
	const [amtToMint, setAmtToMint] = useState(1)
	const [web3, setWeb3] = useState(null)
	const [shiny, setShiny] = useState(null) 
	const [contract, setContract] = useState(null)
 
	const [supplyAvailable, setSupplyAvailable] = useState(0)
	const [balanceOf, setBalanceOf] = useState(0)
	const [addressMintedBalance, setAddressMintedBalance] = useState(0)
	const [paused, setPaused] = useState(false)
	const [isWaitlisted, setIsWaitlisted] = useState(true)
	const [onlyWaitlisted, setOnlyWaitlisted] = useState(false)
	const [minted, setMinted] = useState(false)
	const [proof, setProof] = useState(null)
	const [multipleInjected, setMultipleInjected] = useState(null)
	const [providersLength, setProvidersLength] = useState(null)
	const [NFTPay, setNFTPay] = useState(null)
    
    const [maxSupply, setMaxSupply] = useState(null)
    const [totalSupply, setTotalSupply] = useState(null)

	const [account, setAccount] = useState(null)
	const [currentNetwork, setCurrentNetwork] = useState(null)

	const [blockchainExplorerURL, setBlockchainExplorerURL] = useState('https://etherscan.io/')
	const [openseaURL, setOpenseaURL] = useState('https://opensea.io/')

	const [isMinting, setIsMinting] = useState(false)
	const [isError, setIsError] = useState(false)
	const [message, setMessage] = useState(null)
	const [percentageFill, setPercentageFill] = useState(null)
	const [kidsFed, setKidsFed] = useState(null)
	const [kidsFedPerPerson, setKidsFedPerPerson] = useState(null)
	const [maxSupplyLabel, setMaxSupplyLabel] = useState(null)
	const [totalSupplyLabel, setTotalSupplyLabel] = useState(null)
	const [supplyAvailableLabel, setSupplyAvailableLabel] = useState(null)
	const [priceOfEth, setPriceOfEth] = useState(0)

	//1. Import coingecko-api
	const CoinGecko = require('coingecko-api');

	//2. Initiate the CoinGecko API Client
	const CoinGeckoClient = new CoinGecko();

	
	
	
	


	const loadBlockchainData = async (
    ) => {
		// Fetch Contract, Data, etc.
		if (web3) {
			const networkId = await web3.eth.net.getId()
			setCurrentNetwork(networkId)
			const data = await CoinGeckoClient.coins.fetch('ethereum', {});
			const priceOfEth = parseInt(data.data.market_data.current_price.usd);
			setPriceOfEth(priceOfEth);

			try {
				const shiny = new web3.eth.Contract(Shiny.abi, Shiny.networks[networkId].address)
				setShiny(shiny)
				const contract = shiny._address
				setContract(contract)


				if (networkId !== 5777) {
					setBlockchainExplorerURL(CONFIG.NETWORKS[networkId].blockchainExplorerURL)
					setOpenseaURL(CONFIG.NETWORKS[networkId].openseaURL)
				}
				
				const Price = await shiny.methods.cost().call()
				setPrice(Price)

				const maxSupply = await shiny.methods.maxSupply().call()
				const totalSupply = await shiny.methods.supply().call()
				
                setMaxSupply(maxSupply)
                setTotalSupply(totalSupply)
				setSupplyAvailable(maxSupply - totalSupply)
				setMaxSupplyLabel((maxSupply * 1).toLocaleString(undefined, {maximumFractionDigits:2}))
				setTotalSupplyLabel((totalSupply * 1).toLocaleString(undefined, {maximumFractionDigits:2}))
				setSupplyAvailableLabel((maxSupply - totalSupply).toLocaleString(undefined, {maximumFractionDigits:2}))
				
				const paused = await shiny.methods.paused().call()
				setPaused(paused)

				const balanceOf = await shiny.methods.balanceOf(account).call()
				setBalanceOf(balanceOf)

				const addressMintedBalance = await shiny.methods.addressMintedBalance(account).call()
				setAddressMintedBalance(addressMintedBalance)
				if (addressMintedBalance > 0) {
					setMinted(true)
				}
				
				const onlyWaitlisted = await shiny.methods.onlyWaitlisted().call()
				setOnlyWaitlisted(onlyWaitlisted)

				const percentageFill = (totalSupply/maxSupply)*100
				setPercentageFill(percentageFill)

				const kidsFed = (totalSupply*priceOfEth*0.03*10).toLocaleString(undefined, {maximumFractionDigits:2})
				setKidsFed(kidsFed)

				const kidsFedPerPerson = (addressMintedBalance*priceOfEth*0.03*10).toLocaleString(undefined, {maximumFractionDigits:2})
				setKidsFedPerPerson(kidsFedPerPerson)
				

			} catch (error) {
				setIsError(true)
				if (!shiny) {
					setMessage("Contract not deployed to current network, please change network in MetaMask and refresh the page")
				}
				if (!account && contract) {
					setMessage("Please connect with MetaMask")
				}

			}
		}
	}

	const loadWeb3 = async () => {
		if (typeof window.ethereum !== 'undefined' && !account) {
			const web3 = new Web3(window.ethereum)
			setWeb3(web3)

			const accounts = await web3.eth.getAccounts()

			if (accounts.length > 0) {
				setAccount(accounts[0])
			} else {
				setMessage('Please connect with MetaMask')
			}
		}
	}
    

	const web3Handler = async () => {
		try {
			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider, // required
					options: {
					  infuraId: "186a0e17f40041d489c490c85776879e" // required
					}
				},
				walletlink: {
					package: CoinbaseWalletSDK, 
					options: {
					  infuraId: "186a0e17f40041d489c490c85776879e" 
					},
					connector: async (CoinbaseWalletSDK, options) => {
						console.log('coinbase');
						const provider = new CoinbaseWalletSDK(options);
						console.log(provider);
						await provider.enable();
						console.log(provider);
						return provider;
					}
				},
				"custom-metamask": {
					display: {
					  logo: providers.METAMASK.logo,
					  name: providers.METAMASK.name,
					  description: providers.METAMASK.description
					},
					package: detectEthereumProvider,
					options: {
					  mustBeMetamask: true,
					  infuraId: "186a0e17f40041d489c490c85776879e"
					},
					connector: async (detectEthereumProvider, options) => {
						console.log("trying now");
						const provider = new detectEthereumProvider(options);
						
						if (provider) {
							if (window.ethereum && window.ethereum.isMetaMask) {
								const web3 = new Web3(window.ethereum.isMetaMask);
								setWeb3(web3);
								if (web3) {
									
									try {
										const providersLength = window.ethereum.providers.length;
										setProvidersLength(providersLength);
										setMultipleInjected(true);
										if (typeof providersLength == 'number') {
											try{
												const provider = window.ethereum.providers.find((provider) => provider.isMetaMask);
												const accounts = await provider.request({ method: 'eth_requestAccounts' });
												setAccount(accounts[0])
												window.location.reload();
											} catch (error) {
												window.alert('Connection Denied')
											}
										} else {
										
										}
									} catch (error) {
										if (typeof providersLength == 'number') {
											
										} else {
											const provider = window.ethereum;
											const accounts = await provider.request({ method: 'eth_requestAccounts' });
											setAccount(accounts[0])
											window.location.reload();
										}
		
									}
								}
							} else {
								window.open("https://metamask.io/download/");
							}
						} else {
							console.log('Please install MetaMask!');
						}

						await provider.enable();
						console.log(provider);
						return provider;
						
					}
				  }
			  };
			  
			  const web3Modal = new Web3Modal({
				network: "mainnet", // optional
				disableInjectedProvider: true,
				cacheProvider: false, // optional
				providerOptions // required
			  });
			  const provider = await web3Modal.connect();
			  const web3 = new Web3(provider);
			  setWeb3(web3);
	
		} catch (error) {
		}
	}
		

	const handleInput = event => {
		setAmtToMint(event.target.value)
	}
	const mintNFTHandler = async () => {
		if (!account) {
			window.alert('You need to connect your wallet first!')
			return
		}

		if (amtToMint > (10000 - balanceOf)) {
			window.alert('You can\'t mint this many. Try minting less.')
			return
		}

		if (amtToMint > supplyAvailable) {
			window.alert('There aren\'t enough NFT\'s left to mint that many.')
			return
		}

		if (balanceOf > 10000) {
			window.alert('You\'ve already minted as many as you can!')
			return
		}

		// Mint NFT
		if (shiny) {
			setIsMinting(true)
			setIsError(false)

			await shiny.methods.publicMint(amtToMint).send({ from: account, value: (amtToMint*Price)})
				.on('confirmation', async () => {
					const maxSupply = await shiny.methods.maxSupply().call()
					const totalSupply = await shiny.methods.supply().call()
					setSupplyAvailable(maxSupply - totalSupply)
					setMaxSupplyLabel((maxSupply * 1).toLocaleString(undefined, {maximumFractionDigits:2}))
					setTotalSupplyLabel((totalSupply * 1).toLocaleString(undefined, {maximumFractionDigits:2}))
					setSupplyAvailableLabel((maxSupply - totalSupply).toLocaleString(undefined, {maximumFractionDigits:2}))

					const balanceOf = await shiny.methods.balanceOf(account).call()
					setBalanceOf(balanceOf)
					const addressMintedBalance = await shiny.methods.addressMintedBalance(account).call()
					setAddressMintedBalance(addressMintedBalance)
					const percentageFill = (totalSupply/maxSupply)*100
					setPercentageFill(percentageFill)
	
					const kidsFed = (totalSupply*priceOfEth*0.03*10).toLocaleString(undefined, {maximumFractionDigits:2})
					setKidsFed(kidsFed)
	
					const kidsFedPerPerson = (addressMintedBalance*priceOfEth*0.03*10).toLocaleString(undefined, {maximumFractionDigits:2})
					setKidsFedPerPerson(kidsFedPerPerson)

				})
				.on('error', (error) => {
					window.alert('There was an error. You may have rejected the transaction. Please try again.')
					setIsError(true)
				})
		}

		setIsMinting(false)
	};

	const waitlistMintNFTHandler = async () => {
		if (!account) {
			window.alert('You need to connect your wallet first!')
			return
		}

		if (amtToMint > (5000 - balanceOf)) {
			window.alert('You can\'t mint this many. Try minting less.')
			return
		}

		if (amtToMint > supplyAvailable) {
			window.alert('There aren\'t enough NFT\'s left to mint that many.')
			return
		}

		if (balanceOf > 49) {
			window.alert('You\'ve already minted as many as you can!')
			return
		}

		// waitlistMint NFT
		if (shiny) {
			setIsMinting(true)
			setIsError(false)

			await shiny.methods.waitlistMint(amtToMint, proof).send({ from: account, value: (amtToMint*Price) })
				.on('confirmation', async () => {
					const maxSupply = await shiny.methods.maxSupply().call()
					const totalSupply = await shiny.methods.supply().call()
					setSupplyAvailable(maxSupply - totalSupply)
					setMaxSupplyLabel((maxSupply * 1).toLocaleString(undefined, {maximumFractionDigits:2}))
					setTotalSupplyLabel((totalSupply * 1).toLocaleString(undefined, {maximumFractionDigits:2}))
					setSupplyAvailableLabel((maxSupply - totalSupply).toLocaleString(undefined, {maximumFractionDigits:2}))

					const balanceOf = await shiny.methods.balanceOf(account).call()
					setBalanceOf(balanceOf)
					const addressMintedBalance = await shiny.methods.addressMintedBalance(account).call()
					setAddressMintedBalance(addressMintedBalance)
					const percentageFill = (totalSupply/maxSupply)*100
					setPercentageFill(percentageFill)
	
					const kidsFed = (totalSupply*priceOfEth*0.03*10).toLocaleString(undefined, {maximumFractionDigits:2})
					setKidsFed(kidsFed)
	
					const kidsFedPerPerson = (addressMintedBalance*priceOfEth*0.03*10).toLocaleString(undefined, {maximumFractionDigits:2})
					setKidsFedPerPerson(kidsFedPerPerson)
				})
				.on('error', (error) => {
					window.alert('There was an error. You may have rejected the transaction. Please try again.')
					setIsError(true)
				})
		}

		setIsMinting(false)
	};

	
	const renderHandlePublic = () => {
		return <GridCol sm={12} md={6}><MintLabel for="amtToMint">How many? (10 max)</MintLabel><MintInput onChange={handleInput} type="number" name="amtToMint" id="amtToMint" max="100" min="1" placeholder="1"/><MintButton style={{background: `url(${Cld1})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} onClick={mintNFTHandler}>Mint</MintButton></GridCol>
	}

	const renderHandleOnlyWaitlist = () => {
		if (isWaitlisted) {
		  return <GridCol sm={12} md={6}><MintLabel for="amtToMint">How many? (10 max)</MintLabel><MintInput onChange={handleInput} type="number" name="amtToMint" id="amtToMint" max="100" min="1" placeholder="1"/><MintButton style={{background: `url(${Cld1})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} onClick={waitlistMintNFTHandler}>Mint</MintButton></GridCol>
		} else {
			return 
		}
	}

	const renderHandleWaitlist = () => {
		if (onlyWaitlisted) {
		  return renderHandleOnlyWaitlist();
		} else {
			return renderHandlePublic();
		}
	}

	const renderHandlePaused = () => {
		if (paused) {
		  return <GridCol sm={12} md={6}><MintLabel>Minting is paused!</MintLabel></GridCol>
		} else {
		  return renderHandleWaitlist();
		}
	}

	const renderHandleAddress = () => {
		if (!account) {
		  return 
		} else {
		  return renderHandlePaused();
		}
	}
	
	const renderHandleContract = () => {
	    if(!contract) {
		  return 
	    } else {
	      return renderHandleAddress();
		}
	}

	const renderAccountIsWaitlisted = () => {
		if(isWaitlisted) {
		  return <Connect>YOU ARE WAITLISTED!</Connect>
	  	} else {
		  return <Connect>You are not waitlisted, sorry :(</Connect>;
	    }
	}

	const renderIsWaitlisted = () => {
		if(!account) {
		  return <div><Connect>Connect your wallet</Connect><TextWrapper><Text>If you are having trouble connecting your wallet, on desktop, ensure you are using chrome and have the required extension. If on mobile, open the website within the wallet app browser, not safari. For a video tutorial of how to access the MetaMask mobile app browser, <a style={{color: 'white'}} href="https://www.youtube.com/watch?v=Dru-SsYsdzM" target="_blank">Click Here</a></Text></TextWrapper></div>
	  	} else {
		  return renderAccountIsWaitlisted()
	    }
	}

	const renderOnlyWaitlisted = () => {
		if(onlyWaitlisted) {
			return renderIsWaitlisted()
			} else {
			return <Connect>Public sale is LIVE!</Connect>;
		  }
	}

	const renderProgressBar = () => {
		
		if(!contract) {
		return null
		} else {
		return <ProgressWrapper><ProgressBarWrapper><ProgressBar animated now={percentageFill} /></ProgressBarWrapper><ProgressLabel>{kidsFed} Meals Provided!</ProgressLabel></ProgressWrapper>
		}
	}

	const handleNFTPay = () => {
		if(NFTPay) {
			setNFTPay(false)
		} else {
			setNFTPay(true)
		}
		
	}

	



    useEffect(() => {
        loadWeb3()
        loadBlockchainData()
    }, [account]);

	return (
        <div style={{background: `url(${background})`, backgroundSize: 'cover'}}>
		<MintPageContainer>
			<MintPageNavbar>
					<NavbarLink	style={{background: `url(${Cld1})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} to="/" target='_blank'>
						Return To 4U Fdn.
					</NavbarLink>
					<NavbarLink	style={{background: `url(${Cld3})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} to="/partnerships" target='_blank'>
						Return To Partners
					</NavbarLink>
					{account ? (
						
						<WalletLink
							style={{background: `url(${Cld4})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}
							href={`${blockchainExplorerURL}address/${account}`} target='_blank'>
							{account.slice(0, 5) + '...' + account.slice(38, 42)}
						</WalletLink>
						
						
					) : (
						
						<MetamaskButton style={{background: `url(${Cld4})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} onClick={web3Handler}>Pay With Crypto</MetamaskButton>
						
					)}
					<MetamaskButton style={{background: `url(${Cld4})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} onClick={handleNFTPay}>Pay With Card</MetamaskButton>
				</MintPageNavbar>
			<MintPageMain>
				{NFTPay ? (
					<div style={{position: 'relative'}}>
						<FaTimes style={{cursor: 'pointer', position: 'absolute', zIndex: '999', right: '0', top: '0'}} onClick={handleNFTPay}/>
						<iframe style={{position: 'absolute', zIndex: '998', right: '0', top: '0', border: 'none', height: '575px'}} src="https://payments.nftpay.xyz/iframe/iframe_pay/f6b47762-5870-47e2-8b59-034cd7790ccb" onClick={handleNFTPay}></iframe>
					</div>
					
				) : (
					null
				)}
				<DescriptionPanel>
					<TextWrapper>
						<Title>Shiny & Friends</Title>
					</TextWrapper>
				</DescriptionPanel>
                {!contract ? (
					<div>
					<AmountMinted>
						Mint price: 0.04 ETH
					</AmountMinted>
					<AmountMinted>
						Max 10 per transaction/50 per wallet
					</AmountMinted>
					{renderOnlyWaitlisted()}
					</div>
                ) : !account ? (
					<div>
					<AmountMinted>
						Mint price: 0.04 ETH
					</AmountMinted>
					<AmountMinted>
						Max 10 per transaction/50 per wallet
					</AmountMinted>
					{renderOnlyWaitlisted()}
					</div>
				) : (
					<div>
					<AmountMinted>
                        {totalSupplyLabel}/{maxSupplyLabel} minted!
                    </AmountMinted>	
					{renderOnlyWaitlisted()}			
					{renderProgressBar()}				
					</div>
				)}
				
				
				<MintPanel>
					{renderHandleContract()}
					<ImgShowcase>
						<ImgShowcaseImg
							src={!account ?(
								happy
							) : !contract && isWaitlisted ? (
								excited
							) : onlyWaitlisted && isWaitlisted ? (
								excited
							) : !onlyWaitlisted && !isWaitlisted ?(
								happy
							) : !contract && !isWaitlisted ?(
								sad
							) :	!isWaitlisted ?(
								sad
							) : !isError && isMinting ? (
								excited
							) : isError ?(
								sad
							) : !account ?(
								happy
							) : (
								happy
							)}
							alt="image-showcase"
						/>
					</ImgShowcase>
				</MintPanel>
				{addressMintedBalance !== 0 ? (
					<AmountMinted>
						{`You provided ${kidsFedPerPerson} meals!`}
					</AmountMinted>
				) : (
					null
				)}
				{!contract ? (
					<FooterSection>
						{account &&
						<FooterLinksWrapper>
							<ContractLink style={{background: `url(${Cld2})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} href={openseaURL + account} target="_blank">
								My Opensea</ContractLink>
							<ContractLink style={{background: `url(${Cld3})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} href={`${blockchainExplorerURL}address/${account}`} target="_blank">
								My Etherscan</ContractLink>
						</FooterLinksWrapper>
						} 
					</FooterSection>	
				) : contract && account ? (
					<FooterSection>	
						<FooterLinksWrapper>
								<ContractLink style={{background: `url(${Cld2})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} href={openseaURL + account} target="_blank">
								My Opensea</ContractLink>
								<ContractLink style={{background: `url(${Cld3})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} href={`${blockchainExplorerURL}address/${account}`} target="_blank">
								My Etherscan</ContractLink>							
								<ContractLink style={{background: `url(${Cld4})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} href={`${blockchainExplorerURL}address/${shiny._address}`} target="_blank">
								Contract Address</ContractLink>
						</FooterLinksWrapper>
					</FooterSection>			
				) : contract ? (
					<FooterSection>	
						<FooterLinksWrapper>
								<ContractLink style={{background: `url(${Cld4})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} href="https://etherscan.io/address/0x3F803f2ddd69e27249104c0D371A469A3b5Bd617" target="_blank">
								Contract Address</ContractLink>
						</FooterLinksWrapper>
					</FooterSection>	
				) : (
					<FooterSection>	
						<FooterLinksWrapper>
								<ContractLink style={{background: `url(${Cld2})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} href={openseaURL + account} target="_blank">
								My Opensea</ContractLink>
								<ContractLink style={{background: `url(${Cld3})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} href={`${blockchainExplorerURL}address/${account}`} target="_blank">
								My Etherscan</ContractLink>							
						</FooterLinksWrapper>
					</FooterSection>
				)}
				<ContractInfo>
					{contract && message ? (
						<CurrentNetwork>{message}</CurrentNetwork>
					) : contract && !message ?(
						<ContractConnectedWrapper>
							{CONFIG.NETWORKS[currentNetwork] && (
								<NetworkLabel>
                                    Current Network: {CONFIG.NETWORKS[currentNetwork].name}
                                </NetworkLabel>
							)}

							<RemainingNFTs>
                                {`NFT's Left: ${supplyAvailableLabel}, You minted: ${addressMintedBalance}, You own: ${balanceOf}`}
                            </RemainingNFTs>
							
							
						</ContractConnectedWrapper>
					) : (
						<ContractLink style={{background: `url(${Cld1})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} href="https://etherscan.io/address/0x3F803f2ddd69e27249104c0D371A469A3b5Bd617" target="_blank">
								Contract Address</ContractLink>
					)}
				</ContractInfo>
				<DescriptionPanel>
					<TextWrapper>
						<Heading>Learn More!</Heading>
					</TextWrapper>
				</DescriptionPanel>
				<DescriptionPanel>	
					<TextWrapper>
						<Text>The first tax deductible NFT! 100% of the mint fee goes directly to charity! Charity wallets are recorded on chain, so no funny business is possible. 75% of the mint fee will be donated on chain to No Kid Hungry, a 501c3 Organization.
                        25% of the mint fee will be retained by 4U Foundation, a 501c3 Organization.
						</Text>
					</TextWrapper>
				</DescriptionPanel>
				<PreviewPanel>
					<GridCol2>
						<TextWrapper>
							<Heading>Fast Facts</Heading>
							<List>
								<Text>+ Features over 200 individual handrawn traits</Text>
								<Text>+ Will provide ~10 million meals through No Kid Hungry</Text>
								<Text>+ Supports artists and art programs through 4U Foundation</Text>
								<Text>+ Access to future projects and giveaways</Text>
							</List>
						</TextWrapper>
					</GridCol2>
					<GridCol2>
					<ImgCycleShowcase>
						<ImgCycleImg src= {sample} />
					</ImgCycleShowcase>
					</GridCol2>
				</PreviewPanel>
					
					<RarityContainer>
						<TextWrapper><Heading>Core Rarity Types</Heading></TextWrapper>
						<Carousel breakPoints={breakPoints} showArrows={false}>
								<TraitCard>
									<Rarity>Common</Rarity>
										<Body src={common}></Body>
								</TraitCard>
								<TraitCard>
									<Rarity>Uncommon</Rarity>
										<Body src={uncommon}></Body>
								</TraitCard>
								<TraitCard>
									<Rarity>Rare</Rarity>
										<Body src={rare}></Body>
								</TraitCard>
								<TraitCard>
									<Rarity>Legendary</Rarity>
										<Body src={legendary}></Body>
								</TraitCard>
								<TraitCard>
									<Rarity>Epic</Rarity>
										<Body src={epic}></Body>
								</TraitCard>
						</Carousel>
					</RarityContainer>

		
					<SocialMediaWrap>
						<SocialIcons>
                            <SocialIconLink href="https://discord.gg/rPVKq5gtRR" target="_blank" aria-label="Discord">
                                <FaDiscord />
                            </SocialIconLink>
                            <SocialIconLink href="https://twitter.com/CoLabsLLC" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.instagram.com/colabs_llc" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
				<SponsorCardWrapper>
					<SponsorCard>
						<SponsorTitle>
							Sponsored By:
						</SponsorTitle>
						<SponsorWrapper>
							<SponsorLink href="https://www.nokidhungry.org/" target="_blank">
								<Sponsor src={NKHBlack}/>
							</SponsorLink>
						</SponsorWrapper>
						<SponsorWrapper>
							<SponsorLink href="https://www.4Ufoundation.io" target="_blank">
								<Sponsor src={FdnBlack}/>
							</SponsorLink>	
						</SponsorWrapper>
					</SponsorCard>
				</SponsorCardWrapper>
				<DeductionInstructionsWrapper>
					{contract && minted ? (
						<DeductionInstructionsWrapper>
							<SponsorCard>
								<DeductionInstructionsH1>
									Thank you so much for your support!
								</DeductionInstructionsH1>
								<DeductionInstructionsP>
									100% of the USD value of your mint fee can be attributed to 4U Foundation, Inc. EIN: 87-3013158, Address: 205 N 5th Street, 2L, Brooklyn, NY 11211. You should reduce the amount of your donation by the fair market value of the NFT received, which we estimate to be $1.00 per NFT.
								</DeductionInstructionsP>
								<DeductionInstructionsH1>
									Here is some information you may need to accurately report your donation on your tax return.
								</DeductionInstructionsH1>
								<DeductionInstructionsP>
								Tax laws differ from jurisdiction to jurisdiction. Please consult an accountant with your personal tax circumstances to determine the deductibility of your mint. However, according to the IRS, if the value of your donated property (Ethereum) exceeds $5,000, the donor (you) must get a qualified appraisal. Furthermore, for any donations exceeding $500, the donor must file Form 8283 with their personal tax return. The amount of your donation that is deductible for federal income tax purposes depends on the period of time you have held the asset. If it is short term (less than 1 year) your deduction is limited to your basis. However, if it is long term (over 1 year) the deduction is the Fair Market Value at the time of the donation. Your donation is also reduced by the Fair Market Value of the property received (the NFT). In good faith, we estimate the Fair Market Value to be $1.00 each.	
								</DeductionInstructionsP>
							</SponsorCard>
						</DeductionInstructionsWrapper>
					) : (
						<DeductionInstructionsWrapper>
							<SponsorCard>
								<DeductionInstructionsH1>
									Here is some information you may need to accurately report your donation on your tax return.
								</DeductionInstructionsH1>
								<DeductionInstructionsP>
									Tax laws differ from jurisdiction to jurisdiction. Please consult an accountant with your personal tax circumstances to determine the deductibility of your mint. However, according to the IRS, if the value of your donated property (Ethereum) exceeds $5,000, the donor (you) must get a qualified appraisal. Furthermore, for any donations exceeding $500, the donor must file Form 8283 with their personal tax return. The amount of your donation that is deductible for federal income tax purposes depends on the period of time you have held the asset. If it is short term (less than 1 year) your deduction is limited to your basis. However, if it is long term (over 1 year) the deduction is the Fair Market Value at the time of the donation. Your donation is also reduced by the Fair Market Value of the property received (the NFT). In good faith, we estimate the Fair Market Value to be $1.00 each.  
								</DeductionInstructionsP>
							</SponsorCard>
						</DeductionInstructionsWrapper>
					)}
				</DeductionInstructionsWrapper>
				
			</MintPageMain>
			<RightsWrapper>
				<WebsiteRights>Co-Labs © {new Date().getFullYear()} All rights reserved</WebsiteRights>
			</RightsWrapper>
		</MintPageContainer>
        </div>
	)
}
const breakPoints = [
    { width: 1, itemsToShow: 1 },
	{ width: 465, itemsToShow: 2 },
    { width: 730, itemsToShow: 3 },
    { width: 980, itemsToShow: 4 },
    { width: 1300, itemsToShow: 5, pagination: false },
  ];	
export default ShinyPage;