import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import png6 from '../../images/help.png'
import {
    PartnersContainer,
    PartnersWrapper,
    PartnersRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img,
    BtnLinkR,
} from './PartnersElements'

const PartnersSection = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
          <PartnersContainer lightBg={false} id={"partnerships"}>
              <PartnersWrapper>
                  <PartnersRow imgStart={true}>
                      <Column1>
                      <TextWrapper>
                          <TopLine>It takes a village</TopLine>
                          <Heading lightText={true}>We couldn't do it without you</Heading>
                          <Subtitle darkText={false}>Our partners are vital to our success. Everything we do 4U, is made possible because of them. We are forever thankful for the partnerships we have formed, and look forward to the future we will face together.</Subtitle>
                          <BtnWrap>
                              <BtnLinkR to='/partnerships'
                              onClick={toggleHome}
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact="true"
                              offset={-80}
                              primary={true ? 1 : 0}
                              dark={false ? 1 : 0}
                              dark2={false ? 1 : 0}>{"Our Partnerships"}</BtnLinkR>
                          </BtnWrap>
                      </TextWrapper>
                      </Column1>
                      <Column2>
                        <ImgWrap>
                            <Img src={png6} alt={"superhero"}/>
                        </ImgWrap>
                      </Column2>
                  </PartnersRow>
              </PartnersWrapper>
          </PartnersContainer>
    )
}

export default PartnersSection
