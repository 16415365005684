import React, {useEffect } from 'react';
import './App.css';
import {HashRouter as Router, Routes, Route} from 'react-router-dom'
import ReactGA from 'react-ga';
import Home from './pages/index.js'
import ContactPage from './pages/contact.js'
import PartnershipsPage from './pages/partnerships.js'
import ShinyPage from './pages/shiny.js'

const TRACKING_ID = "UA-225055070-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} exact />
        <Route path="/contact" element={<ContactPage/>} exact />
        <Route path="/partnerships" element={<PartnershipsPage/>} exact />
        <Route path="/shiny" element={<ShinyPage/>} exact />
      </Routes>
    </Router>
  );
}

export default App;
