import React from 'react'
import png5 from '../../images/love.png'
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img,
    BtnLinkS
} from './InfoElements'

const InfoSection = () => {
    return (
          <InfoContainer lightBg={false} id={"about"}>
              <InfoWrapper>
                  <InfoRow imgStart={false}>
                      <Column1>
                      <TextWrapper>
                          <TopLine>{"Creating a charitable metaverse"}</TopLine>
                          <Heading lightText={true}>4U Foundation</Heading>
                          <Subtitle darkText={false}>Despite the decentralization and distribution of blockchain technology, there are still barriers to entry that prevent charities and amateur creators from entering this new and exciting field. 4U Foundation exists to serve as a barrier breaker to help artists gain exposure, and income, with no cost to them, and also for our partners to bring more awareness to their brands, and additional donation sources, while rewarding donors at the same time.</Subtitle>
                          <BtnWrap>
                              <BtnLinkS to='programs'
                              smooth={true}
                              duration={500}
                              spy={true}
                              exact="true"
                              offset={-80}
                              primary={true ? 1 : 0}
                              dark={false ? 1 : 0}
                              dark2={false ? 1 : 0}>{"Our Programs"}</BtnLinkS>
                          </BtnWrap>
                      </TextWrapper>
                      </Column1>
                      <Column2>
                        <ImgWrap>
                            <Img src={png5} alt={"beaker"}/>
                        </ImgWrap>
                      </Column2>
                  </InfoRow>
              </InfoWrapper>
          </InfoContainer>
    )
}

export default InfoSection
