import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom'




export const ProgressWrapper = styled.div`
justify-content: center;
align-self: center;
margin: 15px;
margin-left: 25px;
margin-right: 25px;
`;

export const ProgressBarWrapper = styled.div`
justify-content: center;
max-width: 1000px;
align-self: center;
margin-left: auto;
margin-right: auto;
`;


export const ProgressLabel = styled.h1`
display: flex;
justify-content: center;
align-self: center;
color: white;
text-shadow: 2px 2px rgba(0, 0, 0, 0.9);
font-size: 24px;
margin-top: 10px;
margin-left: auto;
margin-right: auto;
font-weight: 700;
`;

export const MintPageContainer = styled.div`
height: 100%;
background: {background};
`;

export const MintPageNavbar = styled.div`
padding: 12px;
display: grid;
grid-template-columns: repeat(4, 1fr);
justify-items: center;
align-items: center;

@media screen and (max-width: 800px) {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
}

@media screen and (max-width: 400px) {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
  align-items: center;
}
`;

export const NavbarLink = styled(LinkR)`
border: none;
text-decoration: none;
cursor: pointer;
color: #000;
font-weight: 700;
text-align: center;
padding: 2em 2.5em;
`;

export const WalletLink = styled.a`
border: none;
text-decoration: none;
cursor: pointer;
color: #000;
padding: 2em 2.5em;
font-size: auto;
font-weight: 700;
text-align: center;
`;

export const FoundationLink = styled.a`
border: none;
text-decoration: none;
cursor: pointer;
color: #000;
padding: 2em 2.5em;
font-size: auto;
font-weight: 700;
text-align: center;
`;


export const MetamaskButton = styled.a`
border: none;
white-space: nowrap;
text-decoration: none;
cursor: pointer;
color: #000;
padding: 2em 2.5em;
font-size: auto;
font-weight: 700;
text-align: center;
`;


export const MintPageMain = styled.div`
justify-content: center;
text-align: center;
margin-top: 40px;
@media screen and (max-width: 1020px) {
    display: flex;
    flex-direction: column;
}
`;

export const MintPanel = styled.div`
display: flex;
justify-content: center;
text-align: center;
margin: 40px 0;


@media screen and (max-width: 539px) {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}
`;

export const SponsorCardWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
`;

export const SponsorCard = styled.div`
background-color: rgba(255,255,255,0.75);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 15px;
height: 100%;
margin: 25px;
padding: 25px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
max-width: 1000px;
`;

export const SponsorWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
`;

export const SponsorLink = styled.a`
cursor: pointer;
`;

export const Sponsor = styled.img`
height: auto;
width: 100%;
max-width: 1000px;
`;

export const SponsorTitle = styled.h1`
color: #000;
display: flex;
justify-content: center;
`;

export const GridCol = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`;

export const GridCol2 = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`;

export const ImgShowcase = styled.div`
margin-left: 10px;
margin-right: 10px;
@media screen and (max-width: 539px) {
    margin-top: 20px;
}
`;

export const ImgShowcaseImg = styled.img`
max-width: 250px;
height: auto;
box-shadow: 0 1px 3pc rgba(0, 0, 0, 0.9);
`;


export const ImgCycleShowcase = styled.div`
margin-left: 10px;
margin-right: 10px;
margin-top: 20px;
`;

export const ImgCycleImg = styled.img`
max-width: 250px;
height: auto;
box-shadow: 0 1px 3pc rgba(0, 0, 0, 0.9);
`;

export const Description = styled.div`
`;

export const DescriptionPanel = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-top: 40px;
`;

export const TextWrapper = styled.div`
color: #fff;
text-shadow: 2px 2px rgba(0, 0, 0, 0.9);
padding: 10px;
max-width: 800px;
justify-content: center;
justify-self: center;
margin: auto;
`;

export const BannerWrapper = styled.div`
margin-left: 10px;
margin-right: 10px;
margin-top: 35px;
display: flex;
align-items: center;
justify-content: center;
`;

export const BannerImage = styled.img`
max-width: 600px;
width: 100%;
height: auto;
`;

export const Heading = styled.h1`
display: flex;
margin-top: 10px;
margin-bottom: 10px;
font-size: auto;
align-self: center;
justify-content: center;
`;

export const Title = styled.h1`
margin-bottom: 10px;
font-size: 48px;
`;

export const List = styled.ul`
list-style-type: none;
font-size: auto;
padding-left: 0;
margin-bottom: 0;
@media screen and (max-width: 600px) {
    margin-bottom: 25px;
}
`;

export const Item = styled.li`
font-weight: 700;
margin-bottom: 5px;

`;

export const Text = styled.h3`
margin-bottom: 1px;
`;

export const AmountMinted = styled.h1`
color: #fff;
text-shadow: 2px 2px rgba(0, 0, 0, 0.9);
margin-bottom: 5px;
margin-top: 5px;
padding: 2px;
font-size: 24px;
}
`;
export const Connect = styled.h1`
color: #fff;
text-shadow: 2px 2px rgba(0, 0, 0, 0.9);
margin-bottom: 10px;
margin-top: 10px;
padding: 5px;
}
`;

export const MintLabel = styled.label`
color: #fff;
text-shadow: 2px 2px rgba(0, 0, 0, 0.9);
font-weight: 700;
`;

export const MintInput = styled.input`
min-width: 100px;
`;

export const MintButton = styled.button`
border: none;
text-decoration: none;
cursor: pointer;
color: #000;
padding: 1em 2.75em;
margin-top: 2em;
font-size: auto;
font-weight: 700;
text-align: center;

&:hover {
  color: #0d6efd;
}

`;

export const FooterSection = styled.div`
padding: 10px;
margin: 10px;
`;

export const FooterLinksWrapper = styled.div`
padding: 12px;
margin: 50px 0;
display: flex;
justify-content: center;
@media screen and (max-width: 475px) {
  flex-direction: column;
}
`;

export const FooterLinks = styled.a`
border: none;
white-space: nowrap;
text-decoration: none;
cursor: pointer;
color: #000;
padding: 2em 2.5em;
font-size: auto;
font-weight: 700;
text-align: center;
`;

export const CurrentNetwork = styled.h6`
color: #fff;
text-shadow: 2px 2px rgba(0, 0, 0, 1);
margin-top: 10px;
`;

export const ContractInfo = styled.div`
padding: 12px;
font-size: 30px;
`;

export const InfoPanel = styled.div`
display: flex;
justify-content: center;
text-align: center;


@media screen and (max-width: 539px) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
`;

export const ContractLink = styled.a`
border: none;
white-space: nowrap;
text-decoration: none;
cursor: pointer;
color: #000;
padding: 1em 2em;
font-size: auto;
font-weight: 600;
text-align: center;
`;

export const ContractConnectedWrapper = styled.div`
`;

export const NetworkLabel = styled.p`
color: #fff;
font-weight: 700;
text-shadow: 0 2px 2px rgba(0, 0, 0, 1);
padding: 2px;
margin-top: 24px;
`;

export const RemainingNFTs = styled.p`
color: #fff;
font-weight: 700;
text-shadow: 0 2px 2px rgba(0, 0, 0, 1);
padding: 2px;
`;

export const DeductionInstructionsP = styled.p`
color: #000;
padding: 2px;
margin-bottom: 5px;
`;

export const DeductionInstructionsH1 = styled.h3`
color: #000;
padding: 2px;
`;

export const DeductionInstructionsWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
`;

export const RightsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WebsiteRights = styled.small`
  color: #000;
  margin-bottom: 16px;
`;
export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 45px;

  &:hover {
    transform: scale(1.05);
    transition: 0.05s ease-in-out
  }
`;

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 10px;
    margin-left: 10px;
`;

export const PreviewPanel = styled.div`
display: flex;
justify-content: center;
text-align: center;
padding: 15px;

@media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
}
`;

export const RarityContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: 'transparent';
  padding-top: 50px;
  padding-bottom: 25px;
  }
`;

export const Rarity = styled.div`
color: #fff;
text-shadow: 2px 2px rgba(0, 0, 0, 0.9); 
display: inline-block;
font-size: 40px;
`;


export const Body = styled.img`
max-width: 250px;
height: auto;
`;

export const TraitCard = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;