import shiny from "../../images/shiny.jpg"
import colabs from "../../images/png5.png"

export const partnerOne = {
    img: colabs,
    alt: 'Co-Labs',
    partnername: 'Co-Labs',
    projectname: '',
    dsc: 'Co-Labs is our sister for-profit company that does all of our development and art work. Without Co-Labs, none of this would be possible.',
    url: 'https://www.co-labs.studio',
    btnlabel: 'Visit',
    imgStart: false
};

export const partnerTwo = {
    img: shiny,
    alt: 'Shiny',
    partnername: 'No Kid Hungry',
    projectname: 'Shiny',
    dsc: 'Shiny is here to shine a ray of hope on hunger with No Kid Hungry, a charitable organization dedicated to feeding children in need. Every NFT minted provides approximately 1,000 meals to children struggling with hunger. Click the button to mint or learn more.',
    url: '/#/shiny',
    btnlabel: 'Now Minting!',
    imgStart: true

};
