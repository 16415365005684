import React, {useState} from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import Program from '../components/Programs';
import PartnersSection from '../components/PartnersHomeSection';
import Footer from '../components/Footer';
import background from '../images/gradientBg.jpg';


const Home = () => {
    const [isOpen, setIsOpen] = useState(false)
    
    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div style={{background: `url(${background})`, backgroundSize: 'cover'}}>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />    
        <HeroSection /> 
        <InfoSection />
        <PartnersSection />
        <Program />
        
        <Footer />       
        </div>
    );
};

export default Home

