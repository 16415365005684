import React from 'react'

import {
    PartnersContainer,
    PartnersWrapper,
    PartnersRow,
    Column1,
    Column2,
    Column3,
    TextWrapper,
    Heading,
    Subtitle,
    BtnWrap,
    ImgWrap,
    Img,
    BtnLink
} from './PartnerElements'

const PartnersSection = ({
    img,
    alt,
    partnername,
    projectname,
    dsc,
    url,
    btnlabel,
    imgStart
}) => {
    return (
        <PartnersContainer>
        <PartnersWrapper>
            <PartnersRow imgStart={imgStart}>
                <Column1>
                  <ImgWrap>
                    <Img src={img} alt={alt}/>
                  </ImgWrap>
                </Column1>
                <Column2>
                  <TextWrapper>
                      <Heading>
                        {partnername}
                      </Heading>
                      <Subtitle>
                        {dsc}
                      </Subtitle>
                  </TextWrapper>
                </Column2>
                <Column3>
                  <BtnWrap>
                    <BtnLink href={url} target="_blank">
                      {btnlabel}
                    </BtnLink>
                  </BtnWrap>
                </Column3>
            </PartnersRow>
        </PartnersWrapper>
    </PartnersContainer>
    )
}

export default PartnersSection
