import React from 'react'
import Icon1 from '../../images/rocket.png'
import Icon2 from '../../images/png2.png'
import { animateScroll as scroll } from 'react-scroll'
import {
    ProgramContainer,
    ProgramH1,
    ProgramWrapper,
    ProgramCard,
    ProgramIcon,
    ProgramH2,
    ProgramP,   
    ProgramCardLink
} from './ProgramElements'

const Program = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <ProgramContainer id="programs">
            <ProgramH1>Our Programs</ProgramH1>
            <ProgramWrapper>
                <ProgramCard>
                    <ProgramCardLink onClick={toggleHome} to="/contact">
                        <ProgramIcon src={Icon1} />
                        <ProgramH2>Custom Collection</ProgramH2>
                        <ProgramP>We create unique and meaningful NFT collections on behalf of our partners, of which they directly receive a percentage of both initial sales and royalties.</ProgramP>
                    </ProgramCardLink>
                </ProgramCard>
                <ProgramCard>
                    <ProgramCardLink onClick={toggleHome} to="/contact">
                        <ProgramIcon src={Icon2} />
                        <ProgramH2>Artistic Support</ProgramH2>
                        <ProgramP>Deployment fees can be expensive, and the development side of launching an NFT can be complicated and expensive. The money we raise from our sponsors and activities is used to pay for the development side of things for qualifying artists.</ProgramP>
                    </ProgramCardLink>
                </ProgramCard>
            </ProgramWrapper>
        </ProgramContainer>
    )
}

export default Program
