import React from 'react'
import {FaTwitter, FaInstagram, FaDiscord} from 'react-icons/fa'
import logo from '../../images/logo.png'
import { animateScroll as scroll } from 'react-scroll'
import {
    FooterContainer,
    FooterWrap,
    Img,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink,
} from './FooterElements'



const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    return (
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialLogo to='/' onClick={toggleHome}>
                        <Img src={logo} alt="logo"/>
                    </SocialLogo>
                    <SocialMediaWrap>
                        <SocialIcons>
                            <SocialIconLink href="https://discord.gg/rPVKq5gtRR" target="_blank" aria-label="Discord">
                                <FaDiscord />
                            </SocialIconLink>
                            <SocialIconLink href="https://twitter.com/4ufoundation_" target="_blank" aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.instagram.com/4ufoundation_" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="https://etherscan.io/address/0xd35415c25a42d7F97519978b863d8c0b63a934D7" target="_blank" aria-label="Etherscan">Ethereum Wallet</SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
                <WebsiteRights>4U Foundation © {new Date().getFullYear()} All rights reserved</WebsiteRights>
            </FooterWrap>
        </FooterContainer>
    );
};

export default Footer;
